<template>
  <legal-page-template :locale="locale">
    <main class="legal-page__content legal-page-content">
      <h1 class="legal-page-content__title">{{ $t('legal.privacyDoctors.title') }}</h1>

      <div class="legal-page-content__article legal-page-article">
        <h2 class="legal-page-article__title">
          {{ $t('legal.privacyDoctors.ourEngagement') }}
        </h2>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.privacyDoctors.universkinSeeks"
            class="legal-article-section__paragraph"
          >
            <b>{{ $t('legal.privacyDoctors.regulationsApplicableToProtection') }}</b>
          </i18n>

          <i18n
            tag="p"
            path="legal.privacyDoctors.thisPrivacyPolicy"
            class="legal-article-section__paragraph"
          >
            <a class="legal-page-content__link" href="https://skinxs.com" target="_blank"
              >https://skinxs.com</a
            >
            <a
              class="legal-page-content__link"
              href="https://prescriptionskincare.skinxs.com"
              target="_blank"
              >https://prescriptionskincare.skinxs.com</a
            >
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.universkinAlwaysSeeksToComply') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <h2 class="legal-page-article__title">
          I. <br />
          {{ $t('legal.privacyDoctors.protectionOfDoctorsPersonalData') }}
        </h2>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.whenItCollectsPersonalData') }}
          </p>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              1.<br />
              {{ $t('legal.privacyDoctors.purposesOfProcessingOfPersonalData') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.personalDataCommunicated') }}
            </p>

            <ul class="legal-article-section__list list list--dash-style">
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.managementOfOpeningAndUse') }}
              </li>

              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.traceabilityOfData') }}
              </li>

              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.optionalManagementOfPlatform') }}

                <ul class="legal-article-section__paragraph-list list list--disc-style">
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.ensureConectionBetweenDoctorAndUser') }}
                  </li>
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.allowDoctorFromHisAccount') }}
                  </li>
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.respondToPhysiciansRequests') }}
                  </li>
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.eventOfValidation') }}
                  </li>
                </ul>
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.improvementOfPerformance') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.preventionAndDetectionOfFraud') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.managementOfAnyDisputes') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.monitoringAndAnalysis') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.forStatisticalPurposes') }}
              </li>
            </ul>
          </div>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              2.<br />{{ $t('legal.privacyDoctors.categoriesOfPersonalData') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.universkinCollectsAndProcessesFollowing') }}
            </p>

            <ul class="legal-article-section__list list list--dash-style">
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.informationRelatingToCivilStatus') }}

                <ul class="legal-article-section__paragraph-list list list--disc-style">
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.identityData.yourNameAndSurname') }}
                  </li>
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.identityData.yourEmailAddress') }}
                  </li>
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.identityData.yourPhoto') }}
                  </li>
                </ul>
              </li>

              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.informationRelatingToProfessionalLife') }}

                <ul class="legal-article-section__paragraph-list list list--disc-style">
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.professionalData.nameOfClinic') }}
                  </li>
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.professionalData.cabinetOrClinicContactDetails') }}
                  </li>
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.professionalData.descriptionOfOffice') }}
                  </li>
                  <li class="legal-article-subsection__paragraph">
                    {{ $t('legal.privacyDoctors.professionalData.photoOfClinic') }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              3. <br />{{ $t('legal.privacyDoctors.dataPreservation') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.doctorsPersonalDataKeptForDuration') }}
            </p>
          </div>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              4. <br />{{ $t('legal.privacyDoctors.recipientsOfPersonalDoctor') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.personalDataOfDoctor') }}
            </p>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.unlessLegallyOrJudicallyObliging') }}
            </p>

            <p class="legal-article-subsection__paragraph">
              —
              <i18n tag="span" path="legal.privacyDoctors.usersForPurposesOfContactingDoctors">
                <a
                  class="legal-page-content__link"
                  href="https://www.universkin.com"
                  target="_blank"
                  >www.universkin.com</a
                >
                <a class="legal-page-content__link" href="https://skinxs.com" target="_blank"
                  >https://skinxs.com</a
                >
              </i18n>
            </p>

            <p class="legal-article-subsection__paragraph">
              — {{ $t('legal.privacyDoctors.hostingServiceProvider') }}
            </p>

            <p class="legal-article-subsection__paragraph">
              — {{ $t('legal.privacyDoctors.employeesOfUniverskinServices') }}
            </p>
          </div>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              5. <br />{{ $t('legal.privacyDoctors.doctorsRightsOverHisPersonalData') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.doctorHasPossibilityToRequest') }}
            </p>

            <ul class="list list--dash-style">
              <li class="legal-article-subsection__paragraph">
                <b>{{ $t('legal.privacyDoctors.rightOfAccess') }}</b>
                {{ $t('legal.privacyDoctors.rightOfAccessDefinition') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                <b>{{ $t('legal.privacyDoctors.rightToRectify') }}</b>
                {{ $t('legal.privacyDoctors.rightToRectifyDefinition') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                <b>{{ $t('legal.privacyDoctors.rightToLimitProcessing') }}</b>
                {{ $t('legal.privacyDoctors.rightToLimitProcessingDefinition') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                <b>{{ $t('legal.privacyDoctors.rightToPortabilityOfData') }}</b>
                {{ $t('legal.privacyDoctors.rightToPortabilityOfDataDefinition') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                <b>{{ $t('legal.privacyDoctors.withdrawalOfConsent') }}</b>
                {{ $t('legal.privacyDoctors.withdrawalOfConsentDefinition') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                <b>{{ $t('legal.privacyDoctors.rightToSubmitComplaint') }}</b>
                {{ $t('legal.privacyDoctors.rightToSubmitComplaintDefinition') }}
              </li>
            </ul>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.yourAcknowledgeThatYou') }}
            </p>

            <i18n
              tag="p"
              path="legal.privacyDoctors.inOrderToAllowExercise"
              class="legal-article-subsection__paragraph"
            >
              <a href="mailto:dpo@universkin.com" class="legal-page-content__link"
                >dpo@universkin.com</a
              >
            </i18n>
          </div>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <h2 class="legal-page-article__title">
          II. <br />
          {{ $t('legal.privacyDoctors.protectionOfPatientData') }}
        </h2>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.forPurposesOfThisArticle') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>(I) {{ $t('legal.privacyDoctors.patientData') }}</b>
            {{ $t('legal.privacyDoctors.patientDataDefinition') }}
            <a
              class="legal-page-content__link"
              href="https://www.skinxs.com/patient-notice"
              target="_blank"
              >https://www.skinxs.com/patient-notice</a
            >.
          </p>

          <p class="legal-article-section__paragraph">
            <b>(II) {{ $t('legal.privacyDoctors.patient') }}</b>
            {{ $t('legal.privacyDoctors.patientDefinition') }}
          </p>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              1. <br />
              {{ $t('legal.privacyDoctors.rolesAndResponsibilities') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.givenThePurposes') }}
            </p>

            <ul class="legal-article-section__list list list--dash-style">
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.rolesDataController') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.rolesJointController') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.rolesSubcontractor') }}
              </li>
            </ul>
          </div>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              2. <br />
              {{ $t('legal.privacyDoctors.mappingPatientData') }}
            </h3>

            <h4>
              <span>2.1. </span>
              <i18n tag="span" path="legal.privacyDoctors.processingCarriedOutOnPlatform">
                <a href="https://skinxs.com" target="_blank" class="legal-page-content__link"
                  >https://skinxs.com</a
                >
              </i18n>
            </h4>

            <u-table class="legal-article-section__table" :table-config="platformTreatmentsData">
              <template #cellContent="{ cellContent }">
                <ul v-if="cellContent.list">
                  <li
                    v-for="(listItem, index) of cellContent.list"
                    :key="index + listItem.toString()"
                  >
                    <ul v-if="listItem.subList">
                      <span>{{ listItem.text }}</span>
                      <li v-for="sublistItem of listItem.subList" :key="sublistItem">
                        {{ sublistItem }}
                      </li>
                    </ul>
                    <span v-else>{{ listItem }}</span>
                  </li>
                </ul>

                <span v-else> {{ cellContent.text }}</span>
              </template>
            </u-table>

            <h4>
              <span>2.2. </span>
              <i18n tag="span" path="legal.privacyDoctors.processingCarriedOutOnWebshop">
                <a href="https://skinxs.com/" target="_blank" class="legal-page-content__link"
                  >https://prescriptionskincare.skinxs.com</a
                >
              </i18n>
            </h4>

            <u-table class="legal-article-section__table" :table-config="webshopTreatmentsData">
              <template #cellContent="{ cellContent }">
                <ul v-if="cellContent.list">
                  <li v-for="(listItem, index) of cellContent.list" :key="index + listItem">
                    <ul v-if="listItem.subList">
                      <span>{{ listItem.text }}</span>
                      <li v-for="sublistItem of listItem.subList" :key="sublistItem">
                        {{ sublistItem }}
                      </li>
                    </ul>
                    <span v-else>{{ listItem }}</span>
                  </li>
                </ul>

                <span v-else> {{ cellContent.text }}</span>
              </template>
            </u-table>
          </div>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              3. <br />
              {{ $t('legal.privacyDoctors.physiciansResponsibilityForProcessing') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.patientDataProcessedByDoctor') }}
            </p>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.doctorUndertakes') }}
            </p>

            <ul class="legal-article-section__list list list--dash-style">
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.toSetUpAndMaintainAppropriateSecurity') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.toRespectAtAllTimes') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.eventOfPersoanlDataBreach') }}
              </li>
            </ul>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.violationMayAffect') }}
              <br />
              {{ $t('legal.privacyDoctors.ifViolationAffects') }}
            </p>
            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.withSpecificRegard') }}
            </p>
            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.patientInvitedToConnect') }}
            </p>
            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.patientWillBeInvited') }}
            </p>

            <i18n
              tag="p"
              path="legal.privacyDoctors.procedureForOrderingProductsWebshop"
              class="legal-article-subsection__paragraph"
            >
              <a
                href="https://skinxs.com/terms-of-sales"
                target="_blank"
                class="legal-page-content__link"
                >https://skinxs.com/terms-of-sales</a
              >
            </i18n>
          </div>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              4. <br />
              {{ $t('legal.privacyDoctors.responsibilityOfUniverskinForProcessing') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.actingAsJointController') }}
            </p>

            <ul class="legal-article-section__list list list--dash-style">
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.ensureConfidentialityOfPatientData') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.ensureSecurityAndIntegrity') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.doNotUsePatientData') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.notToGrantRentAssign') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.provideAssistanceToDoctorInOrder') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.notToTransferPatientData') }}
              </li>
              <li class="legal-article-subsection__paragraph">
                {{ $t('legal.privacyDoctors.immediatelyAlertDoctorInEventOfViolation') }}
              </li>
            </ul>
          </div>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              5.<br />
              {{ $t('legal.privacyDoctors.secondaryUseOfPatientData') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.doctorRecognizesAndAccepts') }}
            </p>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.doctorDeclaresAndAcknowledgesHaving') }}
            </p>
          </div>

          <div class="legal-article-subsection">
            <h3 class="legal-article-subsection__title">
              6.<br />
              {{ $t('legal.privacyDoctors.releaseOfPatientData') }}
            </h3>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.atEndOfUseOfDoctorsAccount') }}
            </p>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.doctorExpresslyRecognizes') }}
            </p>

            <p class="legal-article-subsection__paragraph">
              {{ $t('legal.privacyDoctors.onlyPatientDataCollectedByDoctor') }}
            </p>
          </div>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <h2 class="legal-page-article__title">
          III. {{ $t('legal.privacyDoctors.securityMeasuresImplementedOnPlatform') }}
        </h2>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.universkinUndertakesToSetUpAndMaintain') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.siteAndWebshopHosted') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.platformAndWebshopHosted') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.inAccordanceWithRegulations') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <h2 class="legal-page-article__title">
          IV. <br />{{ $t('legal.privacyDoctors.managementOfCookies') }}
        </h2>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.universkinUsesCookiesForProper') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.informationCollectedThroughCookies') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.universkinUsesFollowingCookies') }}
          </p>

          <div class="legal-page-table">
            <div class="legal-page-table__row">
              <div class="legal-page-table__title">
                {{ $t('legal.privacyDoctors.performanceCookies') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyDoctors.performanceCookiesDescription') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__title">{{ $t('legal.privacyDoctors.cookieName') }}</div>
              <div class="legal-page-table__title">
                {{ $t('legal.privacyDoctors.storagePeriod') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyDoctors.cookies.liveChat') }}
              </div>
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyDoctors.cookies.threeYears') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyDoctors.cookies.sentry') }}
              </div>
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyDoctors.cookies.threeYears') }}
              </div>
            </div>
          </div>

          <div class="legal-page-table">
            <div class="legal-page-table__row">
              <div class="legal-page-table__title">
                {{ $t('legal.privacyDoctors.statisticsCookies') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyDoctors.statisticsCookiesDescription') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__title">{{ $t('legal.privacyDoctors.cookieName') }}</div>
              <div class="legal-page-table__title">
                {{ $t('legal.privacyDoctors.storagePeriod') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyDoctors.cookies.gid') }}
              </div>
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyDoctors.cookies.oneDay') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyDoctors.cookies.ga') }}
              </div>
              <div class="legal-page-table__cell legal-page-table__cell--underlined">
                {{ $t('legal.privacyDoctors.cookies.fourteenMonths') }}
              </div>
            </div>
            <div class="legal-page-table__row">
              <div class="legal-page-table__cell">{{ $t('legal.privacyDoctors.cookies.gat') }}</div>
              <div class="legal-page-table__cell">
                {{ $t('legal.privacyDoctors.cookies.oneDay') }}
              </div>
            </div>
          </div>

          <i18n
            tag="p"
            path="legal.privacyDoctors.userFreeToConsentToUse"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:info@universkin.com" class="legal-page-content__link"
              >info@universkin.com</a
            >
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.privacyDoctors.userCanAlsoConfigure') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyDoctors.browserConfig.windowsInternetExplorer') }}
            <br />
            <a
              class="legal-page-content__link"
              href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              >https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</a
            >.
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyDoctors.browserConfig.mozillaFirefox') }}
            <a
              class="legal-page-content__link"
              href="https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites"
              target="_blank"
              >https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites</a
            >.
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyDoctors.browserConfig.googleChrome') }}
            <a
              class="legal-page-content__link"
              href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DiOS&hl=fr"
              target="_blank"
              >https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DiOS&hl=fr</a
            >.
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyDoctors.browserConfig.appleSafariIPhoneIPad') }}
            <a
              class="legal-page-content__link"
              href="https://support.apple.com/fr-fr/HT201265"
              target="_blank"
              >https://support.apple.com/fr-fr/HT201265</a
            >.
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyDoctors.browserConfig.appleSafariMac') }}
            <a
              class="legal-page-content__link"
              href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
              target="_blank"
              >https://support.apple.com/fr-fr/guide/safari/sfri11471/mac</a
            >.
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.privacyDoctors.browserConfig.deacticationOfGoogleAnalytics') }}
            <a
              class="legal-page-content__link"
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              >https://tools.google.com/dlpage/gaoptout</a
            >.
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <h2 class="legal-page-article__title">
          V. <br />{{ $t('legal.privacyDoctors.evolutionOfPrivacyPolicy') }}
        </h2>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.privacyDoctors.privacyPolicyCanBeModified"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:info@universkin.com" class="legal-page-content__link"
              >info@universkin.com</a
            >
          </i18n>
        </div>
      </div>
    </main>
  </legal-page-template>
</template>

<script>
import { UTable } from 'universkin-shared';

import LegalPageTemplate from '@/components/templates/LegalPageTemplate';

import { PLATFORM_TREATMENT_DATA, WEBSHOP_TREATMENT_DATA } from '@/modules/dashboard/utils/legal';

export default {
  name: 'PageDoctorNotice',
  components: { LegalPageTemplate, UTable },
  props: {
    locale: {
      type: String,
      default: ''
    }
  },
  metaInfo: {
    title: 'Privacy Notice - Doctors',
    titleTemplate: 'skinXs - %s'
  },
  computed: {
    platformTreatmentsData() {
      return PLATFORM_TREATMENT_DATA(this.$t);
    },
    webshopTreatmentsData() {
      return WEBSHOP_TREATMENT_DATA(this.$t);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/legal-pages/content-styles';
</style>
